import React, {useState} from 'react';

const NotFound = ({ staticContext = {} }) => {
    staticContext.status = 404;
    const [value, setValue] = useState(3);
    if (typeof window !== 'undefined') {
        setInterval(()=>{
            if (value === 0) {
                window.location = '/'
            } else {
                setValue(value-1)
            }
        },1000)
    }
    return <div className="d-flex justify-content-center align-items-center" style={{height: '66vh'}}>
        <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
        <div className="inline-block align-middle">
            <h2 className="font-weight-normal lead" id="desc">The page you requested was not found.</h2>
            {(typeof window !== 'undefined') ? <p>redirect to home in {value} ...</p> : ''}
        </div>
    </div>;
};

export default NotFound;