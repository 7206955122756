import React from 'react'
import Routes from './routes';

const App = (props) => {
    return (
        <React.Fragment>
            <Routes {...props}/>
        </React.Fragment>
    )
}

export default App